<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId=='generalSettingsDetails'" :class="modalState?'show':''" tabindex="-1" role="dialog" style="display: block;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{details.company}}
            </h5>
            <a class="close"  @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="editSettings" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Setting Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Company</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.company.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Company"
                                    @blur="$v.formData.company.$touch()"
                                    v-model.trim="formData.company"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.company.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.company.required"
                            >Company field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.company.minLength"
                            >At least 2 character company.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Address</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.address.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Address"
                                    @blur="$v.formData.address.$touch()"
                                    v-model.trim.number="formData.address"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.address.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.address.required"
                            >Address field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.address.minLength"
                            >At least 2 character address.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Phone</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.phone.$error }">
                            <input
                                    type="number"
                                    class="form-control border-radius-0"
                                    placeholder="Phone"
                                    @blur="$v.formData.phone.$touch()"
                                    v-model.trim.number="formData.phone"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.phone.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.phone.required"
                            >Phone field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.phone.numeric"
                            >Please enter numeric value.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.phone.minLength"
                            >At least 7 character phone.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.phone.maxLength"
                            >Max 15 character phone.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Email</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.email.$error }">
                            <input
                                    type="email"
                                    class="form-control border-radius-0"
                                    placeholder="Email"
                                    @blur="$v.formData.email.$touch()"
                                    v-model.trim.number="formData.email"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.email.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.email.required"
                            >Email field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.email.minLength"
                            >At least 6 character email.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.email.maxLength"
                            >Max 50 character email.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>VAT Number</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.vat_number.$error }">
                            <input
                                    type="number"
                                    class="form-control border-radius-0"
                                    placeholder="VAT Number"
                                    @blur="$v.formData.vat_number.$touch()"
                                    v-model.trim.number="formData.vat_number"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.vat_number.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.vat_number.required"
                            >Email field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.vat_number.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* disable eslint */
  import { mapGetters } from "vuex";
  import {
    maxLength,
    minLength,
    numeric,
    required,
  } from "vuelidate/lib/validators";
  export default {
    data() {
      return {
        details: [],
        formData: {
          logo: "",
          company: "",
          address: "",
          phone: "",
          email: "",
          vat_number: "",
        },
      };
    },
    validations: {
      formData: {
        company: { required, minLength: minLength(2), maxLength: maxLength(255) },
        address: { required, minLength: minLength(2), maxLength: maxLength(255) },
        email: { required, minLength: minLength(2), maxLength: maxLength(255) },
        phone: {
          required,
          numeric,
          minLength: minLength(7),
          maxLength: maxLength(15),
        },
        vat_number: { required, numeric },
      },
    },
    methods: {
      editSettings() {
        this.$store.commit(
                "setApiUrl",
                `api/settings/general/${this.details.id}`
        );
        this.$store.commit("updateData", this.formData);
      },
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "dataId",
        "modalId",
        "modalState",
      ]),
    },
    watch: {
      dataId(value) {
        this.details = this.dataLists.find(function (data) {
          return data.id == value;
        });
        this.formData.logo = this.details.logo;
        this.formData.company = this.details.company;
        this.formData.address = this.details.address;
        this.formData.phone = this.details.phone;
        this.formData.email = this.details.email;
        this.formData.vat_number = this.details.vat_number;
      }
    },
  };
</script>